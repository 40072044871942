
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import SepcTable from './sepc-table.vue'
@Component({
    components: {
        LsDialog,
        SepcTable
    }
})
export default class Table extends Vue {
    @Prop({ default: false }) disabled!: boolean //是否禁用
    @Prop({ default: () => [] }) value!: any[]

    @Prop({ default: false }) isSpec!: boolean
    @Prop({
        default: () => ({})
    })
    extend!: any
    get list() {
        return this.value
    }

    set list(val) {
        this.$emit('input', val)
    }

    get extendPrice() {
        return this.extend.price || []
    }
    handleDelete(index: number) {
        this.list.splice(index, 1)
    }
}

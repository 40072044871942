var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"add-unit"},[_c('ls-dialog',{ref:"lsDialog",attrs:{"async":true,"width":"740px","top":"30vh","title":_vm.value.id ? '编辑单位' : '新增单位',"confirmButtonText":"保存"},on:{"cancel":_vm.closeDialog,"confirm":_vm.handleSave}},[_c('div',[_c('el-form',{ref:"form",attrs:{"model":_vm.value,"label-width":"120px","size":"small"}},[_c('el-form-item',{attrs:{"label":"单位名称","required":"","prop":"name","rules":[
                        {
                            required: true,
                            message: '请输入单位名称',
                            trigger: ['blur', 'change']
                        }
                    ]}},[_c('el-input',{staticStyle:{"width":"380px"},attrs:{"placeholder":"请输入单位名称"},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}})],1),_c('el-form-item',{attrs:{"label":"排序"}},[_c('el-input',{staticStyle:{"width":"220px"},attrs:{"placeholder":""},model:{value:(_vm.value.sort),callback:function ($$v) {_vm.$set(_vm.value, "sort", $$v)},expression:"value.sort"}}),_c('div',{staticClass:"xs muted"},[_vm._v("排序值必须为整数；数值越小，越靠前")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import GDialog from './dialog.vue'
import List from './list.vue'
import GTable from './table.vue'
@Component({
    components: {
        GDialog,
        List,
        GTable
    }
})
export default class GoodsSelect extends Vue {
    // 数据
    @Prop({ default: () => [] }) value!: any[] | object
    // 多选/单选
    @Prop({ default: 'multiple' }) type!: 'multiple' | 'single'
    // 是否开启
    @Prop({ default: false }) disabled!: boolean
    // 最多选择数量
    @Prop({ default: 50 }) limit!: number
    // 选中的显示模式
    @Prop({ default: 'list' }) mode!: 'table' | 'list'
    // 是否开启多规格
    @Prop({ default: false }) isSpec!: boolean
    // 表格多规格情况下的扩展
    @Prop() extend!: any[]
    @Prop({ default: () => {} }) params!: Record<any, any>
    // 是否展示虚拟商品
    @Prop({ default: false }) showVirtualGoods?: boolean
    get selectData() {
        return this.value
    }
    set selectData(val) {
        this.$emit('input', val)
        this.$emit('change', val)
    }
}
